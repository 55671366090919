import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "vertical-app vertical-flow"
}
const _hoisted_2 = {
  key: 0,
  style: {"color":"red"}
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "vertical-flow__form__step vertical-flow__form__step--header" }
const _hoisted_5 = { class: "vertical-flow__form__step__title" }
const _hoisted_6 = { class: "vertical-flow__form__step__text vertical-flow__form__step__text--initial" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["id"]
const _hoisted_9 = ["data-gtm", "data-gtm-click-text", "onClick"]
const _hoisted_10 = ["textContent"]
const _hoisted_11 = { class: "vertical-flow__form__step__text" }
const _hoisted_12 = ["textContent"]
const _hoisted_13 = {
  key: 0,
  class: "vertical-flow__form__step__subtitle"
}
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  key: 0,
  class: "vertical-flow__form__step__accordion text-center"
}
const _hoisted_16 = { class: "vertical-flow__form__step__content" }
const _hoisted_17 = ["data-gtm", "data-gtm-click-text", "onClick", "textContent"]
const _hoisted_18 = {
  key: 1,
  style: {"text-align":"center","width":"100%"},
  class: "mt-5"
}
const _hoisted_19 = { style: {"color":"red","border":"2px solid var(--color-transparent, #00b9ea)","border-radius":"8px","padding":"40px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerticalStepComponent = _resolveComponent("VerticalStepComponent")!

  return (_ctx.renderPage)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.model.mockedDataLoaded)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_2, "Api failed -> Mocked request loaded!"))
          : _createCommentVNode("", true),
        (!_ctx.loadError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", {
                      class: "header-sub-title",
                      innerHTML: _ctx.getCustomerInfo()
                    }, null, 8, _hoisted_7)
                  ])
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.proposalSteps, (step, inx) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: 'step' + inx
                }, [
                  _createElementVNode("div", {
                    id: '_' + step.id,
                    class: _normalizeClass([
						'vertical-flow__form__step',
						{ 'vertical-flow__form__step--inactive': !_ctx.isActiveStep(step) },
						{ 'vertical-flow__form__step--validated': _ctx.isValid(step) && !_ctx.isActiveStep(step) },
					])
                  }, [
                    _createElementVNode("div", {
                      class: "vertical-flow__form__step__title",
                      "data-gtm": step.dataGtm,
                      "data-gtm-click-text": step.title,
                      onClick: ($event: any) => (_ctx.gotoStep(step))
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(['vertical-flow__form__step__number', { subtitle: step.subtitle }]),
                        textContent: _toDisplayString(_ctx.isValid(step) && !_ctx.isActiveStep(step) ? '' : inx + 1)
                      }, null, 10, _hoisted_10),
                      _createElementVNode("div", _hoisted_11, [
                        _withDirectives(_createElementVNode("h4", {
                          textContent: _toDisplayString(step.title)
                        }, null, 8, _hoisted_12), [
                          [_vShow, step.title]
                        ]),
                        ((step.subtitle && !_ctx.isActiveStep(step)) || step.subtitle)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                              _createElementVNode("div", {
                                class: "subtitle-content",
                                innerHTML: step.subtitle
                              }, null, 8, _hoisted_14)
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ], 8, _hoisted_9),
                    _createVNode(_Transition, { name: "slide-vertical-no-delay-fast" }, {
                      default: _withCtx(() => [
                        (_ctx.isActiveStep(step))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                              _createElementVNode("div", _hoisted_16, [
                                _createVNode(_component_VerticalStepComponent, {
                                  "visible-error": _ctx.visibleError,
                                  step: step
                                }, null, 8, ["visible-error", "step"])
                              ]),
                              _createElementVNode("a", {
                                class: _normalizeClass(['btn-solid-secondary mt-3 mb-4', { 'btn-disabled': !_ctx.isValid(step) }]),
                                "data-gtm": step.dataGtm,
                                "data-gtm-click-text": step.title,
                                onClick: ($event: any) => (_ctx.nextStep(step)),
                                textContent: _toDisplayString(step.buttonLabel)
                              }, null, 10, _hoisted_17)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ], 10, _hoisted_8)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ], 512)), [
        [_vShow, _ctx.showPage]
      ])
    : (_ctx.loadError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.loadError), 1)
        ]))
      : _createCommentVNode("", true)
}
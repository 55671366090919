import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.footerLinks?.length > 0)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['cookie-footer', { 'cookie-footer--themed': _ctx.isThemed }, { 'cookie-footer--app': _ctx.isLoggedInAsApp() }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.footerLinks, (item, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            _createElementVNode("a", {
              href: item.url,
              target: item.target,
              class: _normalizeClass(['cookie-footer__link', { 'ml-2': index > 0 }, { 'mr-2': index === 0 }])
            }, _toDisplayString(item.title), 11, _hoisted_1),
            _createTextVNode(" " + _toDisplayString(index != _ctx.footerLinks.length - 1 ? '|' : undefined), 1)
          ], 64))
        }), 128))
      ], 2))
    : _createCommentVNode("", true)
}
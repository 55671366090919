import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_autocomplete = _resolveComponent("autocomplete")!

  return (_openBlock(), _createBlock(_component_autocomplete, {
    id: _ctx.autocompleteId,
    ref: _ctx.acRef,
    autocomplete: _ctx.autocompleteNative,
    results: _ctx.results,
    debounce: _ctx.debounce,
    placeholder: _ctx.placeholder,
    max: _ctx.max,
    "input-class": _ctx.classList,
    "display-item": _ctx.displayItem,
    valid: _ctx.valid,
    error: _ctx.error,
    onInput: _ctx.search,
    onOnSelect: _ctx.handleSubmit,
    onBlur: _ctx.blur,
    onFocus: _ctx.focus
  }, null, 8, ["id", "autocomplete", "results", "debounce", "placeholder", "max", "input-class", "display-item", "valid", "error", "onInput", "onOnSelect", "onBlur", "onFocus"]))
}
import { Options, Vue } from 'vue-class-component';
import PageTitleComponent from '@/sharedcomponents/pageComponents/pageTitle/PageTitleComponent.vue';
import { AcceptPage } from '@/cms/definitions/content-types';
import store from '@/store/store';
import CookieFooter from '@/sharedcomponents/pageComponents/cookieFooter/cookieFooter.vue';
import VerticalContainerComponent from './VerticalContainerComponent.vue';
import { handleIdleLogout, isDevelop } from '@/services/environmentService';
import { PROPOSAL_LOADED } from '@/store/modules/ProposalContext';

@Options({
	name: 'DigitalAcceptComponent',
	components: {
		PageTitleComponent,
		VerticalContainerComponent,
		CookieFooter,
	},
	props: {
		model: Object,
	},
})
export default class DigitalAcceptComponent extends Vue {
	public model: AcceptPage; // epi contentModel
	public usabillaBlock: any = null;
	public usabillaBlockLeaveIntent: any = null;
	public renderPage = false;
	public showHeadline = false;
	public showFooterHack = false;
	public headline = '...';
	public isDevelop = isDevelop;

	public beforeCreate() {
		handleIdleLogout('/logout', 30); // TODO KLK - remove data from session(?)
	}

	public async beforeMount() {
		store.state.showSpinner = true;

		this.handlePreTitle();
		window.addEventListener('resize', this.handlePreTitle);
		store.subscribeAction((action, state) => {
			if (action.type === PROPOSAL_LOADED) {
				setTimeout(() => {
					this.showHeadline = true;
				}, 100);
			}
		});
		this.renderPage = true;
	}

	public beforeUnmount() {
		// Todo klk proper Vue event
		window.removeEventListener('resize', this.handlePreTitle);
	}

	private handlePreTitle() {
		this.headline = this.model.headline;
		// screen.width < 768 ? this.preTitle = this.cms.shortTitle : this.preTitle = this.model.preTitle;
	}
}

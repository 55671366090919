import { defaultConfig } from '@/cms/api/ApiConfig';
import store from '@/store/store';
import AxiosService from './axiosService';
import { isDevelop } from './environmentService';
import TestConfig from '@/views/digitalAccept/TestData/TestData';
import { createDigitalAcceptSubmitRequest } from './acceptSubmitService';
import { ConsentResponse } from '@/types/types';
import { createConsentBody } from './consentService';

// TODO ERROR Submit
export const getProposal = async () => {
	const baseUrl = defaultConfig.baseUrl + store.state.proxyUrl;
	const proposeUrl = `/quote-accept/quote/${store.state.ProposalContext.quote_id}?customer_no=${store.state.ProposalContext.customerNo}&signature=${store.state.ProposalContext.signatureId}`;
	const axiosService = new AxiosService();
	let proposal: any;

	try {
		const url = baseUrl + proposeUrl;
		proposal = await axiosService.get(url);
	} catch (e) {
		console.error('TODO cannot load proposal', e);
	}

	if (proposal?.status !== 200 && isDevelop) {
		console.warn('fallback DEV load HardCoded proposal');
		proposal = TestConfig.getPolicyProposalHardCoded3();
	}
	return proposal;
};

// TODO ERROR Submit
export const getInsuranceCompanies = async () => {
	const baseUrl = defaultConfig.baseUrl + store.state.proxyUrl;
	const proposeUrl = `/quote-accept/insurance-companies`;
	const axiosService = new AxiosService();
	let proposal: any;

	try {
		const url = baseUrl + proposeUrl;
		proposal = await axiosService.get(url);
	} catch (e) {
		console.error('TODO cannot load insurance companies', e);
	}

	return proposal;
};
// TODO ERROR Submit
export const submitProposal = async () => {
	const baseUrl = defaultConfig.baseUrl + store.state.proxyUrl;
	const proposeUrl = `/quote-accept/quote/${store.state.ProposalContext.quote_id}?customer_no=${store.state.ProposalContext.customerNo}&signature=${store.state.ProposalContext.signatureId}`;
	const axiosService = new AxiosService();
	const url = baseUrl + proposeUrl;
	const proposalRes = await axiosService.patch(url, createDigitalAcceptSubmitRequest());
	return proposalRes;
};

// TODO ERROR Submit
export const getConsent = async (): Promise<{ data: ConsentResponse; status: any }> => {
	const baseUrl = defaultConfig.baseUrl + store.state.proxyUrl;
	// const consentUrl = `/quote-accept/quote/${store.state.ProposalContext.quote_id}/consents?customer_no=${store.state.ProposalContext.customerNo}&signature=${store.state.ProposalContext.signatureId}`;

	const consentUrl = `/quote-accept/quote/0Q03M000000BOFESA4/consents?customer_no=9678927778&signature=b38b6d46745031d670e4fd7651b592138470d211ad8e427136db3d35a2a40d90`;
	const axiosService = new AxiosService();
	let consentResponse: any;

	try {
		const url = baseUrl + consentUrl;
		consentResponse = await axiosService.get(url);
	} catch (e) {
		console.error('TODO cannot load consent', e);
	}

	if (consentResponse?.status !== 200 && isDevelop) {
		console.warn('fallback DEV load HardCoded consent');
		consentResponse = TestConfig.getPolicyConsentHardCoded();
	}
	// TODO perhaps popup with load error and ask user to try again later or call us?
	return consentResponse;
};

// TODO ERROR Submit
export const submitConsent = async () => {
	const baseUrl = defaultConfig.baseUrl + store.state.proxyUrl;
	const consentUrl = `/quote-accept/quote/${store.state.ProposalContext.quote_id}/consents?customer_no=${store.state.ProposalContext.customerNo}&signature=${store.state.ProposalContext.signatureId}`;
	const axiosService = new AxiosService();
	const url = baseUrl + consentUrl;
	const consentRes = await axiosService.put(url, createConsentBody());
	return consentRes;
};

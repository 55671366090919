import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "SearchContainer text-left" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "InputTextField__container" }
const _hoisted_4 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelComponent = _resolveComponent("LabelComponent")!
  const _component_AutocompleteComponent = _resolveComponent("AutocompleteComponent")!
  const _component_inputFieldComponent = _resolveComponent("inputFieldComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_LabelComponent, {
        label: _ctx.label,
        tooltip: _ctx.tooltip,
        isHtmlLabel: true,
        readonly: !!_ctx.readonlyValue,
        idFor: _ctx.autocompleteId
      }, null, 8, ["label", "tooltip", "readonly", "idFor"]),
      (!_ctx.readonlyValue)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "InputTextField__wrapper",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setActive && _ctx.setActive(...args))),
            onOntouchstart: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setActive && _ctx.setActive(...args))),
            tabindex: "0",
            onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.setActive && _ctx.setActive(...args)))
          }, [
            _createElementVNode("label", {
              class: "InputTextField__wrapper_label",
              for: _ctx.autocompleteId
            }, [
              _createElementVNode("div", {
                class: _normalizeClass([
							'InputTextField__stateWrapper',
							{ InputTextField__stateWrapper_active: _ctx.active },
							{ InputTextField__stateWrapper_border: _ctx.isValid },
							{ InputTextField__stateWrapper_valid: _ctx.isValid },
							{ InputTextField__stateWrapper_error: _ctx.showError },
							{ InputTextField__stateWrapper_searching: _ctx.searching },
							{ InputTextField__stateWrapper_searchIcon: _ctx.searchReady },
						])
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", {
                    class: _normalizeClass([
									'InputTextField__label',
									{ InputTextField__label_active: _ctx.active },
									{ InputTextField__label_noLabel: !_ctx.placeholder || !!_ctx.readonlyValue },
								])
                  }, _toDisplayString(!!_ctx.readonlyValue ? undefined : _ctx.placeholder), 3),
                  _createVNode(_component_AutocompleteComponent, {
                    autocompleteName: "search",
                    autocompleteNative: "off",
                    onInput: _ctx.search,
                    onOnSelect: _ctx.handleSubmit,
                    onBlur: _ctx.blur,
                    onFocus: _ctx.onFocus,
                    autocompleteId: _ctx.autocompleteId,
                    results: _ctx.results,
                    acRef: 'resultList',
                    ___placeholder: _ctx.placeholder,
                    max: 200,
                    valid: _ctx.inputValid,
                    error: _ctx.showError,
                    "display-item": _ctx.displayItem,
                    onAutoCompleteField: _ctx.setAutoCompleteField,
                    classList: ['InputTextField__input', { InputTextField__input_active: _ctx.active }]
                  }, null, 8, ["onInput", "onOnSelect", "onBlur", "onFocus", "autocompleteId", "results", "___placeholder", "valid", "error", "display-item", "onAutoCompleteField", "classList"])
                ])
              ], 2)
            ], 8, _hoisted_2)
          ], 32))
        : (_openBlock(), _createBlock(_component_inputFieldComponent, {
            key: 1,
            id: _ctx.autocompleteId,
            ___placeholder: _ctx.placeholder,
            readonlyValue: _ctx.readonlyValueDisplay
          }, null, 8, ["id", "___placeholder", "readonlyValue"]))
    ]),
    (_ctx.showError)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          role: "alert",
          class: "SearchContainer__error",
          textContent: _toDisplayString(_ctx.errorMessage)
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}
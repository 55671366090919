// https://www.npmjs.com/package/vue3-autocomplete
import Autocomplete from 'vue3-autocomplete';
import { Options, Vue } from 'vue-class-component';

@Options({
	name: 'SearchComponent',
	components: {},
	props: {
		autocompleteId: { type: String, default: '_' + Math.random() },
		defaultValue: { type: String, default: undefined },
		label: String,
		resultList: Array,
		optionsList: Array,
		placeholder: { type: String, default: '' },
		searchByCleanedInput: { type: Boolean, default: false },
		isValid: { type: Boolean, default: false },
		readonlyValue: { type: String, default: null },
		visibleError: { type: Boolean, default: false },
		errorMessage: { type: String, default: 'Du skal vælge fra listen' },
		dropdown: { type: Boolean, default: false },
		tooltip: String,
	},
})
export default class SearchComponent extends Vue {
	autocompleteId: string;
	defaultValue?: string; // if model has value
	label: string;
	resultList: Array<string>;
	optionsList: Array<string>;
	results: Array<string> = [];
	placeholder?: string;
	searchByCleanedInput?: boolean; // replace non chars in searchString with space
	isValid?: boolean; // valid searchResult
	readonlyValue?: string;
	visibleError: boolean;
	errorMessage: string;
	dropdown: boolean;
	tooltip: string;

	private localResultList: Array<string> = []; // local lowercase copy of resultList
	private hasFocus = false;
	private doBlur = true;
	private autocomplete: Autocomplete;
	public searching = false;
	public dirty = true;
	public readonlyValueDisplay: string = null;
	public active = false;

	public beforeMount() {
		if (this.readonlyValue) {
			this.readonlyValueDisplay = this.getValueFromLabelsOrOptions(this.readonlyValue);
			return;
		}

		this.resultList.forEach((res) => {
			if (this.searchByCleanedInput) {
				this.localResultList.push(res.toLocaleLowerCase().replace(/[\W_]+/g, ' ')); // replace all non chars with space
			} else {
				this.localResultList.push(res.toLocaleLowerCase());
			}
		});
	}

	get inputValid() {
		return !this.dirty && this.isValid && !this.searching && !this.readonlyValue;
	}
	get searchReady() {
		return !this.readonlyValue && !this.searching && !this.isValid /*|| this.dirty*/ && !this.showError;
	}

	get showError() {
		return (
			this.visibleError &&
			!this.hasFocus &&
			!this.readonlyValue &&
			!this.searching &&
			/*!this.dirty &&*/ !this.isValid
		);
	}
	public setAutoCompleteField(input) {
		this.autocomplete = input;

		if (this.defaultValue) {
			const value = this.getValueFromLabelsOrOptions(this.defaultValue);
			this.autocomplete.setText(value);
			this.dirty = false;
			this.active = true;
		}
	}

	private getValueFromLabelsOrOptions(value) {
		let inx = this.resultList.indexOf(value);
		if (inx === -1) {
			inx = this.optionsList.indexOf(value);
			if (inx !== -1) {
				return this.resultList[inx];
			}
			//Not good. Value is not in the list!
		}
		return value;
	}

	public displayItem(item) {
		return item.matchText;
	}

	public async search(input): Promise<any> {
		this.searching = true;
		this.doBlur = true;
		this.dirty = true;
		if (!this.dropdown && input.length < 1) {
			this.results = [];
			this.searching = false;
			this.$emit('search', {
				matchText: undefined,
				id: this.autocompleteId,
			});

			return;
		}
		const response = await this.getLocalResultList(input);
		if (response.status !== 200) {
			this.results = [];
			this.searching = false;
			this.$emit('search', {
				matchText: undefined,
				id: this.autocompleteId,
			});
			return;
		}
		if (response.data.matches.length > 0) {
			this.results = response.data.matches;
		}
		this.searching = false;

		// UI DEBUG keep resultList open
		// setTimeout(() => {
		// 	if (this.results && this.results.length > 2) {
		// 		const container = document.querySelector('.vue3-results-container');
		// 		const clone = container.cloneNode(true);
		// 		document.querySelector('.vue3-autocomplete-container').appendChild(clone);
		// 	}
		// }, 1000);
	}
	private async getLocalResultList(input: string): Promise<any> {
		const result: any = {
			status: 200,
			data: {
				matches: [],
			},
		};
		if (this.dropdown && input?.length < 1) {
			// get everything
			this.resultList.forEach((elem, inx) => {
				result.data.matches.push({
					matchText: this.resultList[inx],
				});
			});
			return Promise.resolve(result);
		}

		let inputLower = input.toLocaleLowerCase();
		if (this.searchByCleanedInput) {
			inputLower = inputLower.replace(/[\W_]+/g, ' '); // replace all non chars with space
		}

		this.localResultList.forEach((elem, inx) => {
			if (elem.indexOf(inputLower) >= 0) {
				result.data.matches.push({
					matchText: this.resultList[inx],
				});
			}
		});
		return Promise.resolve(result);
	}

	public async onFocus(evt) {
		this.dirty = true;
		this.hasFocus = true;
		this.$emit('searchfocus', evt);
		await this.search(this.autocomplete.searchText);
	}

	public blur() {
		if (this.doBlur && this.results.length > 0) {
			if (this.autocomplete.searchText !== this.results[0]) {
				this.handleSubmit(this.results[0]);
			}
		}

		this.active = !!this.autocomplete.searchText;
		this.hasFocus = false;
		this.doBlur = false;
		this.dirty = false;
	}

	public handleSubmit(result) {
		this.autocomplete.setText(result.matchText);
		this.$emit('search', {
			matchText: result.matchText,
			id: this.autocompleteId,
		});

		this.doBlur = false;
		this.dirty = false;
		this.autocomplete.autocompleteRef.blur();
	}
	public setActive(evt) {
		if (evt.srcElement.classList.contains('vue3-results-item')) {
			return;
		}
		this.active = true;
		this.autocomplete.autocompleteRef.focus();
	}
}

import { Options, Vue } from 'vue-class-component';
import { isApp } from '@/services/environmentService';
import store from '@/store/store';
import { Link } from '@/cms/definitions/content-types';

@Options({
	name: 'CookieFooter',
	props: {
		footerLinks: Object,
	},
})
export default class CookieFooter extends Vue {
	footerLinks: Array<Link>; // from epi contentModel

	public get isThemed() {
		return store.getters.getThemeContext;
	}

	public isLoggedInAsApp() {
		return isApp;
	}
}

import { Options, Vue } from 'vue-class-component';

@Options({
	name: 'RadioComponent',
	props: {
		name: String,
		id: String,
		preSelect: { type: String, default: undefined },
		disabled: { type: Boolean, default: false },
		label: String,
		tooltip: String,
		options: Array,
		labels:Array,
		visibleError: Boolean,
		validationErrorText: String,
	},
})
export default class RadioComponent extends Vue {
	name!: string;
	id!: string;
	preSelect?: any;
	disabled?: boolean;
	change: boolean = false;
	label: string;
	tooltip: string;
	options: Array<string>;
	labels: Array<string>;
	visibleError: boolean;
	validationErrorText: string;

	// LIFECYCLE
	public mounted() {
		if (this.preSelect !== undefined) {
			this.options.forEach(option => {
				if (this.preSelect === option) {
					this.changeItem(option);
				}
					
			});
		}
	}

	// METHODS
	public changeItem(item: string) {
		const refItem = (this as any).$refs[this.name + this.id + item];
		(refItem as HTMLInputElement).checked = true;
		this.changed(item);
	}

	public changed(item: string) {
		this.$emit('RadioComponent', {
			name: this.name,
			id: this.id + item,
			value: item ?? false,
		});
	}

	public get showError() {
		return this.visibleError && !this.change;
	}

}

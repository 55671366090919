import { createApp } from 'vue';
import App from '@/App.vue';
import router from './router';
import store from '@/store/store';
import appInsights, { AppInsightsOptions } from '@/appinsights/appinsights';
import { defaultCmsConfig } from '@/cms/CmsConfig';
defaultCmsConfig.contentArea.rootHtmlTag = 'section';

import PopoverComponent from '@/sharedcomponents/baseComponents/popoverComponent/popoverComponent.vue';
import SegmentedControlComponent from '@/sharedcomponents/baseComponents/segmentedControlComponent/segmentedControlComponent.vue';
import SelectModalComponent from '@/sharedcomponents/baseComponents/selectModalComponent/selectModalComponent.vue';
import CheckboxComponent from '@/sharedcomponents/baseComponents/CheckboxComponent/CheckboxComponent.vue';
import RadioComponent from '@/sharedcomponents/baseComponents/RadioComponent/RadioComponent.vue';
import InputFieldComponent from '@/sharedcomponents/baseComponents/inputFieldComponent/InputFieldComponent.vue';
import TextOnlyFieldComponent from '@/sharedcomponents/baseComponents/TextOnlyFieldComponent/TextOnlyFieldComponent.vue';
import DatePickerComponent from '@/sharedcomponents/baseComponents/datePickerComponent/DatePickerComponent.vue';
import SearchComponent from '@/sharedcomponents/baseComponents/searchComponent/SearchComponent.vue';
import ToggleModalComponent from '@/sharedcomponents/baseComponents/toggleModalComponent/ToggleModalComponent.vue';
import AutocompleteComponent from '@/sharedcomponents/baseComponents/autoCompleteComponent/AutocompleteComponent.vue';
import AddressSearchComponent from './sharedcomponents/baseComponents/addressSearchComponent/AddressSearchComponent.vue';
import ButtonTapComponent from './sharedcomponents/baseComponents/buttonTapComponent/buttonTapComponent.vue';
import LabelComponent from './sharedcomponents/baseComponents/LabelComponent/LabelComponent.vue';
import ContactComponent from './sharedcomponents/pageComponents/contact/ContactComponent.vue';

import { setVersion } from './services/environmentService';

// Register all Episerver view components globally. This requires webpack!
// Otherwise we need to register all components manually here in main.ts.
const app = createApp(App)
	.use(store)
	.use(router)
	.use(appInsights, { router } as AppInsightsOptions)
	.component('PopoverComponent', PopoverComponent)
	.component('SegmentedControlComponent', SegmentedControlComponent)
	.component('CheckboxComponent', CheckboxComponent)
	.component('RadioComponent', RadioComponent)
	.component('InputFieldComponent', InputFieldComponent)
	.component('TextOnlyFieldComponent', TextOnlyFieldComponent)
	.component('SelectModalComponent', SelectModalComponent)
	.component('DatePickerComponent', DatePickerComponent)
	.component('SearchComponent', SearchComponent)
	.component('ToggleModalComponent', ToggleModalComponent)
	.component('AutocompleteComponent', AutocompleteComponent)
	.component('AddressSearchComponent', AddressSearchComponent)
	.component('ButtonTapComponent', ButtonTapComponent)
	.component('LabelComponent', LabelComponent)
	.component('ContactComponent', ContactComponent);
const addPageComponents = (requireComponent) => {
	requireComponent.keys().forEach((fileName) => {
		const componentConfig = requireComponent(fileName);

		// Gets the component name regardless folder depth
		const namePart = fileName.split('/').pop() ?? '';
		const componentName = namePart.replace(/\.\w+$/, '');

		// Look for the component options on `.default`, which will
		// exist if the component was exported with `export default`,
		// otherwise fall back to module's root.
		// console.log("### adding CMS page componentName:", componentName);
		app.component(componentName, componentConfig.default || componentConfig);
	});
};

// console.log("Register CMS pages Digital Accept");
const requireComponent = require.context('@/views/digitalAccept/pages', true, /.vue$/);
addPageComponents(requireComponent);

app.mount('#app');

setVersion();

import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopoverComponent = _resolveComponent("PopoverComponent")!

  return (_ctx.label)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isHtmlLabel)
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              class: _normalizeClass(['LabelComponent', { LabelComponent_readonly: !!_ctx.readonly }]),
              for: _ctx.idFor
            }, _toDisplayString(_ctx.label), 11, _hoisted_2))
          : (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: "LabelComponent",
              textContent: _toDisplayString(_ctx.label)
            }, null, 8, _hoisted_3)),
        (_ctx.tooltip)
          ? (_openBlock(), _createBlock(_component_PopoverComponent, {
              key: 2,
              type: "icon",
              class: "page-component__popover text-left",
              buttonClass: "popover__icon popover__icon__questionmark",
              innerData: _ctx.tooltip
            }, null, 8, ["innerData"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
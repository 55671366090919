import { ADD_PROPOSAL_INFO } from '@/store/modules/ProposalContext';
import store from '@/store/store';
import { cloneDeep } from 'lodash';
import { isDevelop } from '@/services/environmentService';
import { formatter } from '@/services/FormatService';
import { insuranceCompany } from '@/types/types';
export default class ModelMapper {
	private proposal: any;
	private cmsModel: any;

	constructor(proposal: any, cmsModel: any) {
		this.proposal = proposal.data;
		this.cmsModel = cmsModel;
		store.dispatch(ADD_PROPOSAL_INFO, proposal.data);
		console.log('this.proposal', this.proposal);
		console.log('this.cmsModel', this.cmsModel);
	}

	public getProposalSteps(): Array<any> {
		const proposalSteps = [];
		// store?
		this.mapPolicies(this.proposal.policies, proposalSteps);
		this.mapPreviousInsuranceStep(proposalSteps);
		this.mapConsentStep(proposalSteps);
		this.mapPaymentStep(proposalSteps);
		return proposalSteps;
	}

	private mapPolicies(policies, proposalSteps) {
		const removeFields = [];
		policies.forEach((policy, inx) => {
			this.flattenProductFields(policy);
			const cmsPolicy = this.getCmsPolicy(policy.type);
			cmsPolicy.id = policy.id;
			let previousFieldId: string;
			if (cmsPolicy.inputFields) {
				cmsPolicy.inputFields.forEach((cmsField) => {
					if (!cmsField.inactive) {
						cmsField.isValid = false;
						const apiField = policy.policy_object.hasOwnProperty(cmsField.id);
						if (apiField) {
							this.mapApiToModel(cmsField, policy.policy_object[cmsField.id]);
						} else {
							// set readonly false if field is not in API
							cmsField.readonly = false;
						}

						this.generalMapField(cmsPolicy, cmsField, previousFieldId, inx);
						// if (cmsField.contentType !== 'TextOnlyField') {
						// TODO KLK perhaps set only if field is not textReadonly???
						previousFieldId = cmsField.id;
						// }
						// console.log('mappedField', cmsField);
					} else {
						console.warn('remove Ignored CMSfield', cmsField.id);
						
						removeFields.push(cmsField.id);
					}
				});
				cmsPolicy.inputFields = cmsPolicy.inputFields.filter(field => !removeFields.includes(field.id) );
				// navn skal mappes til primær bruger, hvis primær bruger ikke er sat
				// cover_start_date: ligger på product og ikke field
				// termination fields skal mappes
			} else {
				cmsPolicy.inputFields = [];
			}

			cmsPolicy.title = this.getPolicyTitle(cmsPolicy.type);
			cmsPolicy.dataGtm = 'TODO tracking variable'; // Todo

			if (!policy.description) {
				console.warn('Product Field is not valid policy.description');
				policy.description = 'TODO Product Field is not valid policy.description';
			}
			cmsPolicy.subtitle = `
                <div class="flex flex__space">
                    <span>${policy.description}</span> <span><span>${formatter.format(
				policy.price
			)} kr./år</span></span>
                </div>`;
			cmsPolicy.buttonLabel = this.cmsModel.adjustPoliciesBlock.buttonLabel;
			proposalSteps.push(cmsPolicy);
			// console.log('cmsPolicy inputFields', cmsPolicy.inputFields);

			if (isDevelop) {
				this.healthCheck(policy, cmsPolicy);
			}
		});
	}

	/**
	 * 
	 * @param policy Puts fields in the policy.policy_object Object, that are placed on lower levels
	 * This goes for the field cover_start_date and the policy.termination fields
	 * @returns void
	 */
	private flattenProductFields(policy) {

		// termination fields mapped to field structure
		// "previous_company_id": "00001",
		// "previous_policy_id": "1234567890",
		// "takeover_date": "2024-01-16"

		const propertyNames = Object.keys(policy.termination);
		propertyNames.forEach((name) => {
			policy.policy_object[name] = policy.termination[name];
		});

		//cover_start_date mapped to field
		policy.policy_object.cover_start_date = policy.cover_start_date
	}
	private healthCheck(policy, cmsPolicy) {
		console.log('HEALTHCHECK - DEVELOP');
		const onlyCmsFields = ['cancelExistingPolicy', 'cancelExistingPolicyOptions', 'cancelExistingPolicyText'];
		// console.log('policy', policy);
		// console.log('cmsPolicy', cmsPolicy);
		let match = undefined;
		console.log('Testing CMS fields ******************************');
		cmsPolicy.inputFields.forEach((cmsField) => {
			//match = policy.fields.find((field) => field.id === cmsField.id);
			match = policy.policy_object.hasOwnProperty(cmsField.id);
			if (!match) {
				match = onlyCmsFields.find((fieldId) => fieldId === cmsField.id);
				if (!match) {
					console.log('Cms field not in product', cmsField.id, cmsField, 'policy', policy.policy_object[cmsField.id]);
				}
			}
		});
		console.log('Testing Product fields ******************************');

		for (const property in policy.policy_object) {
			const match = cmsPolicy.inputFields.find((cmsField) => cmsField.id === property);
			if (!match) {
				console.log('Product field not in cms', property);
			}
		}

		console.log('END HEALTHCHECK - DEVELOP ******************************');
	}

	private mapPreviousInsuranceStep(proposalSteps: Array<any>): void {
		const step = this.mapStep(
			this.cmsModel.previousInsuranceBlock,
			'previousBlock',
			this.cmsModel.previousInsuranceBlock.buttonLabel
		);
		proposalSteps.push(step);
	}

	private mapConsentStep(proposalSteps: Array<any>): void {
		
		const step = this.mapStep(this.cmsModel.consentsBlock, 'consentBlock', this.cmsModel.consentsBlock.buttonLabel);
		proposalSteps.push(step);
	}

	private mapPaymentStep(proposalSteps: Array<any>): void {
		const step = this.mapStep(
			this.cmsModel.paymentMethodBlock,
			'paymentBlock',
			this.cmsModel.paymentMethodBlock.buttonLabel
		);

		// map payment fields values from this.proposal.party.payment values ;
		step.inputFields.forEach(cmsField => {
			
			const apiField = this.proposal.party.payment.hasOwnProperty(cmsField.id);
		
			if (apiField) {
				this.mapApiToModel(cmsField, this.proposal.party.payment[cmsField.id]);
			}
			
		});


		this.mapProductPriceBlock(step);
		proposalSteps.push(step);
	}

	public getReceiptPolicyDetails(): any {
		const step = this.mapStep(this.cmsModel, 'receiptBlock', this.cmsModel.buttonLabel);
		this.mapProductPriceBlock(step);
		return step;
	}

	private mapProductPriceBlock(step) {
		const policies = this.proposal.policies;

		policies.forEach((policy, inx) => {
			const title = this.getPolicyTitle(policy.type);
			if (!policy.description) {
				console.warn('Product Field is not valid policy.description');
			}

			const policyEntry = `<div class="policy-entry"><strong>${title}</strong><br/>
            <div class="flex flex__space">
             <span>${policy.description}</span> <span>${formatter.format(policy.price)} kr./år</span>
            </div>
            <br/> </div>`;

			const uiField = {
				id: title + inx,
				uiId: title + inx,
				value: policyEntry,
				contentType: 'TextOnlyField',
				isVisible: true,
				isValid: true,
			};
			step.inputFields = [uiField, ...step.inputFields];
		});

		const price = step.inputFields.find((field) => field.id === 'price');
		const txt = `
            <span><h3 class="mt-2">${this.replaceHtmlParagraph(price.value)}</h3></span> 
            <div class="flex flex__space mb-n5 mt-n5">
            <span><h3>(inkl. alle rabatter)</h3></span> <span><h3>${formatter.format(
				this.proposal.price
			)} kr./år</h3></span>
            </div>`;
		price.value = txt;
		price.uiId = price.id;
		price.isVisible = true;
		price.isValid = true;

		const fees = step.inputFields.find((field) => field.id === 'fees');
		fees.value =
			this.replaceHtmlParagraph(fees.value) +
			' ' +
			formatter.format(this.proposal.fees) +
			' kr. <br/><br/>';
		fees.isVisible = true;
		fees.isValid = true;
	}

	private generalMapField(cmsPolicy, cmsField, previousFieldId, inx) {
		cmsField.uiId = cmsField.id + inx; // unique id for html element

		if (cmsField.isDependentPrevious && previousFieldId) {
			cmsField.dependentId = previousFieldId;
			// console.log('mapping field', cmsField.id);
			// console.log('field', cmsField);
		}

		if (cmsField.labels || cmsField.options) {
			if (cmsField.labels) {
				cmsField.labels = cmsField.labels.split('\n').filter((elem) => elem !== '');
			}

			if (cmsField.options) {
				cmsField.options = cmsField.options.split('\n').filter((elem) => elem !== '');
			}



			if (!cmsField.labels) {
				cmsField.labels = cmsField.options;
			}

			if (!cmsField.options) {
				cmsField.options = cmsField.labels;
			}

			this.handleInsuranceCompanies(cmsPolicy, cmsField);

			if (cmsField.labels.length !== cmsField.options.length) {
				console.warn(
					'List labels and options differ in length -> id',
					cmsField.id,
					'Labels length;',
					cmsField.labels.length,
					'Options length:',
					cmsField.options.length
				);
			}
			// if (cmsField.id === 'yearly_mileage')
			// console.log('HIT LABELS', cmsField.id ,cmsField.labels, cmsField.options);
		}

		if (cmsField.contentType === 'CheckField') {
			cmsField.isValid = !cmsField.isRequired;
			if (cmsField.masterId) {
				cmsField.class = cmsField.class ? cmsField.class + ' checkbox-child' : 'checkbox-child';
				cmsPolicy.hasMasterChildCheckFields = true;
			}
		}

		// replace Optimizely inserted <p> with <span>
		if (cmsField.contentType === 'TextOnlyField') {
			cmsField.isValid = true;
			if (cmsField.value) {
				cmsField.value = this.replaceHtmlParagraph(cmsField.value);
			}
		}

		if (cmsField.contentType === 'NumberField') {
			cmsField.contentType = 'TextField';
			cmsField.type = 'number';
		}

		if (cmsField.tooltip.includes('<p>')) {
			cmsField.tooltip = this.replaceHtmlParagraph(cmsField.tooltip);
		}
	}

	private handleInsuranceCompanies(cmsPolicy, cmsField) {
		if (cmsField.id === 'previous_company_id') {
			const insuranceCompanies = store.state.ProposalContext.insuranceCompanies;
			if (insuranceCompanies && insuranceCompanies?.length > 0) {
				cmsField.labels = [];
				cmsField.options = [];
				insuranceCompanies.forEach(company => {
					if (this.isValidCompanyForProduct(cmsPolicy.type, company) ) {
						cmsField.labels.push(company.company_name);
						cmsField.options.push(company.company_id + '');
					} 
				});
			}
		}
	}

	private isValidCompanyForProduct(productType, company: insuranceCompany) {
		const typeUpper = productType.toLocaleUpperCase();
		switch(typeUpper) {
			case 'CAR' : return company.is_auto_insurer; 
			case 'ULYKKE': return company.is_accident_insurer; 
			default: return true;
		}


	}

	private replaceHtmlParagraph(htmlValue) {
		return htmlValue.replaceAll('<p>', '<span>').replaceAll('</p>', '</span>');
	}

	private mapStep(cmsBlock, blockId, buttonLabel) {
		const step: any = {};
		step.title = cmsBlock.stepHeadline;
		this.simplifyInputFields(cmsBlock);

		let previousFieldId: string;
		const removeFields = [];

		cmsBlock.inputFields.forEach((field, inx) => {
			if (!field.inactive) {
				this.generalMapField(cmsBlock, field, previousFieldId, inx);
				previousFieldId = field.id;
			} else {
				console.warn('remove Ignored CMSfield', field.id);
				removeFields.push(field.id);
			}
		});
		cmsBlock.inputFields = cmsBlock.inputFields.filter(field => !removeFields.includes(field.id) );	

		step.inputFields = cmsBlock.inputFields;
		step.hasMasterChildCheckFields = cmsBlock.hasMasterChildCheckFields;

		// missing
		step.id = blockId;
		step.dataGtm = 'TODO tracking variable';
		step.subtitle = undefined;
		step.buttonLabel = buttonLabel;
		return step;
	}

	private mapApiToModel(cmsField, apiFieldValue) {
		// if (cmsField.id === 'license_plate') {
		// 	console.log('cmsField.value', cmsField.value);
		// 	console.log('apiField.value', apiFieldValue);
		// }		
		const hasValue = this.hasValue(apiFieldValue);
		// if (cmsField.id === 'license_plate') {
		// 	console.log('hasValue', hasValue);
		// }		
		cmsField.isValid = hasValue;

		if (hasValue) {
			cmsField.value = apiFieldValue + '';
		}
		
		// sanity check
		if (cmsField.readonly && !hasValue) {
			// if readonly and no value
			cmsField.readonly = false;
			cmsField.isValid = false;
		}
	}

	private hasValue(value): boolean {
		if (value === undefined || value === null || value === 'null' || value === 'undefined') {
			return false;
		} else if ((value + '').trim() === '') {
			return false;
		}
		return true;
	}

	private getCmsPolicy(policyType: string) {
		const cmsPolicy = this.cmsModel.adjustPoliciesBlock.policyBlock.items.find((policyBlock) => {
			if (policyBlock.content.type.toLocaleLowerCase() === policyType.toLocaleLowerCase()) {
				return policyBlock;
			}
		});
		// always work on copy of content structure
		const policy = cloneDeep(cmsPolicy.content);
		this.simplifyInputFields(policy);
		return policy;
	}

	// remove api layers
	private simplifyInputFields(step) {
		const inputFields = [];
		step.inputFields.items.forEach((field) => {
			inputFields.push(field.content);
		});
		step.inputFields = inputFields || [];
	}

	private getPolicyTitle(type: string) {
		let title: string;
		const typeUpper = type.toLocaleUpperCase();
		switch (typeUpper) {
			case 'HUND':
				title = 'Hundeforsikring';
				break;
			case 'HEST':
				title = 'Hesteforsikring';
				break;
			case 'CAR':
				title = 'Bilforsikring';
				break;
			case 'INDBO':
				title = 'Indboforsikring';
				break;
			case 'ULYKKE':
				title = 'Ulykkesforsikring';
				break;
			default:
				title = type;
		}
		return title;
	}
}

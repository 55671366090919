import FieldValidator from '@/services/fieldValidatorService';
import { Options, Vue } from 'vue-class-component';

@Options({
	name: 'InputFieldComponent',
	components: {},
	props: {
		id: String,
		name: String,
		placeholder: String,
		label: String,
		tooltip: String,
		type: String, // html attribute
		required: Boolean, // html attribute
		autocomplete: String, // html attribute
		inputValue: String, // direct value to HtmlInput value
		validateOnLoad: Boolean,
		readonlyValue: { type: String, default: null },
		fieldValidatorType: { type: String, default: null }, // which fieldValidator to use
		innerData: { type: Object, default: {} }, // innerData is an object reference with a name that governs HtmlInput value
		visibleError: { type: Boolean, default: false },
		inputClassOverride: String, // overrides success class - but not error class
		errorMsgOverride: String,
		min: { type: Number, default: 0 },
		max: { type: Number, default: 0 },
	},
})
export default class InputFieldComponent extends Vue {
	id: string;
	name: string;
	placeholder: string;
	label: string;
	tooltip?: string;
	type: string;
	required: boolean;
	autocomplete: string;
	inputValue: string;
	validateOnLoad: boolean;
	readonlyValue?: string;
	fieldValidatorType?: string;
	innerData: any;
	visibleError: boolean;
	inputClassOverride: string;
	errorMsgOverride: string;
	min: number;
	max: number;

	private fieldValidator: FieldValidator;
	public validationErrorFormatText: string = '';
	public htmlInputValue: string = '';
	public hasError = false;
	public hasFocus = false;
	private validateMinMax = false;

	public active = false;

	public beforeMount() {
		if (this.fieldValidatorType) {
			this.fieldValidator = new FieldValidator(this.fieldValidatorType);
		} else {
			this.fieldValidator = new FieldValidator(this.name);
		}
	}

	public mounted() {
		if (this.readonlyValue) {
			this.active = true;
			return;
		}

		this.validateMinMax = this.min !== 0 || this.max !== 0;

		const hasInnerData = this.innerData[this.name] !== undefined && (this.innerData[this.name] + '').length > 0;
		if (this.inputValue) {
			this.htmlInputValue = this.inputValue;
		} else if (hasInnerData) {
			this.htmlInputValue = this.innerData[this.name];
		}

		if (this.validateOnLoad) {
			this.validateInput('blur');
		}

		if (this.readonlyValue || this.htmlInputValue) {
			this.active = true;
		}
	}
	public get showError() {
		const showError =
			this.visibleError && !this.hasFocus && !this.readonlyValue && (this.hasError || !this.validate());

		if (showError && this.validationErrorFormatText === '') {
			this.validationErrorFormatText = this.errorMsgOverride || this.fieldValidator.errorMsg;
		}
		return showError;
	}

	public get isValid() {
		return this.readonlyValue || (!this.inputClassOverride && this.validate());
	}

	// METHODS
	public change(evt) {
		const input = evt.target || evt.srcElement;
		this.handleInput({
			name: this.name,
			value: input.value,
			event: evt,
		});
	}

	public handleInput(data) {
		this.htmlInputValue = data.value;
		this.validateInput(data.event.type);
		this.innerData[this.name] = data.value;
		if (data.event.type === 'focus') {
			this.hasFocus = true;
			this.$emit('InputFocus', data.value);
		}
		if (data.event.type === 'input') {
			this.$emit('InputChanged', data.value);
		}
		if (data.event.type === 'blur') {
			this.hasFocus = false;
			this.$emit('InputBlur', data.value);
		}
	}

	private validateInput(event: string) {
		if (this.readonlyValue) {
			return;
		}
		// TODO KLK ???
		if (
			event === 'blur' ||
			(event === 'keyup' && this.validationErrorFormatText) ||
			(event === 'input' && this.htmlInputValue?.length >= 1)
		) {
			// make sure error doesn't show instantly
			setTimeout(() => {
				this.hasError = !this.validate();
				this.validationErrorFormatText = !this.hasError
					? ''
					: this.errorMsgOverride || this.fieldValidator.errorMsg;
			}, 100);
			this.active = this.htmlInputValue?.length >= 1;
			return;
		}
		this.active = true;
	}

	private validate() {
		if (this.validateMinMax) {
			if (this.type === 'number') {
				const v = parseInt(this.htmlInputValue);
				return v >= this.min && (v <= this.max || this.max === 0);
			}
			if (this.htmlInputValue) {
				return (
					this.htmlInputValue.length >= this.min && (this.max === 0 || this.htmlInputValue.length <= this.max)
				);
			} else {
				return false;
			}
		}
		return this.fieldValidator.isValid(this.htmlInputValue);
	}
}
